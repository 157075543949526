<template>
	<div class="list-users">
		<div class="list-users__heading">
		</div>

		<table-list
			:items="users"
			:loading="loading"
		>
			<template slot="header">
				<span
					:class="`users-header-item users-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					<component :is="orderBy[key] ? 'a' : 'span'" @click="sort(key)"> {{ col }}</component>
					<ion-icon :name="query.order == 'asc' ? `caret-up` : `caret-down`" v-if="orderBy[key] && orderBy[key] == query.orderBy"></ion-icon>
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<router-link
					:class="`users-item users-item--${key} users-item--${item['status']}`"
					:key="`item_${key}`"
					:to="{ name: 'user', params: { uid: item.uid } }"
					v-for="(col, key) in columns"
					v-html="transform(key, get(item, key))"
				>
				</router-link>
			</template>
		</table-list>
	</div>
</template>

<script>
import { format, parseISO, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'
import { get } from 'lodash'
import { UserStatus } from '@/lib/enums'

import TableList from '@/components/TableList'

export default {
	name: 'ListUsers',
	components: {
		TableList,
	},
	props: {
		loading: Boolean,
		users: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		columns: {
			nameEmail: 'Name',
			// profileName: 'Name',
			// email: 'Email',
			organization: 'Organization',
			permissions: 'Permissions',
			'settings.salesforceId': 'Salesforce ID',
			// dateCreated: 'Created',
			dateLogin: 'Last Login',
			status: 'Status',
		},
		order: 'asc',
	}),
	computed: {
		applications() {
			let applications = this.$store.getters['application/applications']
			applications = [
				...applications,
				...[
					{
						appId: 'admin',
						name: 'Administrator',
					},
					{
						appId: 'super',
						name: 'Super Admin',
					},
					{
						appId: 'bval',
						name: 'ARC Pricing - BVAL',
					},
				]
			]
			return applications
		},
		query() {
			return this.$route.query
		},
		orderBy() {
			let orderBy = {
				nameEmail: 'firstName',
				dateCreated: 'dateCreated',
				organization: 'organization.name',
				'settings.salesforceId': 'salesforceId',
				dateLogin: 'dateLogin',
				status: 'status',
			}

			return orderBy
		},
	},
	methods: {
		sort(key) {
			if (this.orderBy[key]) {
				const query = {
					...this.$route.query,
					orderBy: this.orderBy[key],
					order: this.$route.query.order == 'asc' ? 'desc' : 'asc',
				}
				this.$router.push({ query })
			}
		},
		transform(key, value) {
			switch(key) {
				case 'organization':
					if (!value) return
					let toReturn = value.name
					if (value.address) {
						toReturn += '<br />'+ [value.address.city, value.address.state].join(', ')
					}
					return toReturn
				case 'dateCreated':
					try {
						value = `${format(parseISO(value), 'PPp')}`
					} catch (error) {
						console.warn(error)
					}
					return value
				case 'dateLogin':
					if (!value) return '(no login yet)'
					try {
						const dt = parseISO(value)
						value = `<span title="${format(dt, 'Pp')}">${formatDistanceToNowStrict(dt, { addSuffix: true})}</span>`
					} catch (error) {
						console.warn(error)
					}
					return value
				case 'status':
					return UserStatus[value]
				case 'permissions':
					let list = `<ul class="list-users__app-list">`
					let _appList = []
					for (const key in value) {
						let app = this.applications.find(a => a.appId == key && value[key])
						if (app) {
							_appList.push(app.name)
						}
					}
					_appList.sort()
					list += `<li>${_appList.join(`</li><li>`)}</li>`
					list += `</ul>`
					return list
				default:
					break
			}

			return value
		},
		get,
	},
}
</script>

<style scoped lang="scss">
.list-users {
	&__heading {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
	}

	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: minmax(100px, 1.5fr) repeat(4, minmax(100px, 1fr)) minmax(100px, auto);

			@include modules.media-query('phone') {
				grid-template-columns: minmax(50%, 1fr) minmax(50%, 1fr);
			}
		}

		.list-users__app-list {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
	}

	.users-header-item {
		@include modules.fontSize(14px);
		align-items: center;
		display: flex;
		font-weight: 600;
		padding: 1em 1.5em;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.users-item {
		color: modules.color_('text', 'dark');
		font-weight: 500;
		overflow-wrap: break-word;
		padding: 1.5em;
		text-decoration: none;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.5em 1em;
		}

		&--pending {
			&.users-item--status {
				color: modules.color_('announce', 'text');
			}
		}

		&--removed {
			opacity: 0.7;

			&.users-item--status {
				color: modules.color_('alert', 'critical')
			}
		}
	}
}
</style>

<template>
	<form class="form form--user form-user" @submit.prevent="submitForm(fields)">
		<div class="form__loader" v-if="loading || isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="First Name" v-model="fields.firstName" :required="true" />
				<text-field class="form__field" label="Last Name" v-model="fields.lastName" :required="true" />
				<text-field class="form__field" label="Email Address" type="email" autocomplete="email" v-model="fields.email" :required="true" />
				<text-field class="form__field" label="Phone" v-model="fields.phone" />
				<select-field class="form__field" label="Status" v-model="fields.status" :options="userStatus" />
				<autocomplete-organization @selected="orgSelect" :value="fields.orgId" :label="(fields.organization || {}).name" />
				<!-- <select-field class="form__field" label="Organization" v-model="fields.orgId" :options="organizations" /> -->
				<checkbox class="form__field" label="Send Password Reset" v-model="fields.sendMail" />
			</div>

			<h2 class="form-section__title">Address Information</h2>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="City" v-model="fields.address.city" />
				<select-field class="form__field" label="State" v-model="fields.address.state" :options="states" />
				<text-field class="form__field" label="Postal Code" v-model="fields.address.postal" />
			</div>

			<h2 class="form-section__title">Permissions</h2>
			<div class="form-row">
				<checkbox class="form__field" v-for="(app, key) in applications" :key="`application_${key}`" name="permission" :label="app.name" v-model="fields.permissions[app.appId]" />
				<!-- <template v-if="fields.permissions.arc && isCoreUser">
					<checkbox class="form__field" v-model="fields.permissions.bval" label="ARC BVAL" />
				</template> -->
			</div>

			<h2 class="form-section__title">Settings</h2>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Job Title" v-model="fields.settings.jobTitle" />
				<text-field class="form__field" label="Salesforce ID" v-model="fields.settings.salesforceId">
					<a slot="post" @click="getSalesforceId()">sync</a>
				</text-field>
			</div>

			<template v-if="fields.permissions.arc">
				<h2 class="form-section__title">ARC Daily Emails</h2>
				<div class="form-row form-row--col-5">
					<template v-for="(day, key) in arcDays">
						<checkbox class="form__field" v-model="fields.settings.arcDaily[key]" :value="key" :key="key" :label="day" />
					</template>
				</div>
			</template>

			<div class="alert alert--fail" v-if="error">
				{{ error }}
			</div>
			<div class="form__actions form__actions--horizontal">
				<a-button class="button--primary form__action" type="submit" :disabled="loading || isLoading">
					{{ fields.uid ? 'Update' : 'Create' }} User
				</a-button>

				<button class="button button--critical form__action" type="button" :disabled="loading || isLoading" @click="remove(fields)" v-if="fields.uid && fields.status != 'removed'">
					Remove User
				</button>
			</div>

		</div>
	</form>
</template>

<script>
import AButton from '@/components/AButton'
import AutocompleteOrganization from '@/components/AutocompleteOrganization'
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import { UserStatus } from '@/lib/enums'
import stateList from '@/assets/stateList.json'
import { pick, get } from 'lodash'

export default {
	name: 'FormUser',
	components: {
		AButton,
		AutocompleteOrganization,
		Checkbox,
		LoadingSpinner,
		SelectField,
		TextField
	},
	props: {
		loading: Boolean,
		user: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			address: {},
			permissions: {},
			settings: {
				arcDaily: [],
			},
		},
		error: null,
		fields: {},
		isLoading: false,
		states: stateList,
		arcDays: {
			1: 'Monday',
			2: 'Tuesday',
			3: 'Wednesday',
			4: 'Thursday',
			5: 'Friday',
		},
	}),
	computed: {
		organizations() {
			const orgs = this.$store.getters['organization/organizations'].filter(o => o.status != 'removed')

			const organizations = []
			for (const org of orgs) {
				organizations.push({
					value: org.orgId,
					label: [org.name, org.address.city, org.address.state].join(' - '),
				})
			}
			return organizations
		},
		me() {
			return this.$store.getters['user/me']
		},
		isCoreUser() {
			return this.user?.email?.match(/(@(5inline|southstatebank)\.com)$/i)
		},
		applications() {
			const apps = this.$store.getters['application/applications']
			if (this.me?.permissions?.arc && this.isCoreUser) {
				apps.unshift({
					appId: 'bval',
					name: 'ARC Pricing - BVAL',
				})
			}
			apps.sort((a, b) => (a.name > b.name) ? 1 : -1)

			if (this.me?.permissions?.super) {
				apps.unshift({
					appId: 'super',
					name: 'Super Admin',
				},
				{
					appId: 'admin',
					name: 'Administrator',
				})
			}
			return apps
		},
		userStatus() {
			let status = []
			for (let key in UserStatus) {
				status.push({
					value: key,
					label: UserStatus[key],
				})
			}

			return status
		},
	},
	methods: {
		orgSelect(selected) {
			this.fields.orgId = selected.value
			this.fields.orgSearch = selected.label
			this.fields.organization = selected
		},
		setArcSettings(key, state) {
			this.fields.settings.arcDaily = this.fields.settings.arcDaily || {}
			this.fields.settings.arcDaily[key] = state
		},
		submitForm(fields) {
			this.error = null
			if (fields.uid) {
				this.isLoading = true
				this.$store.dispatch('user/update', { uid: fields.uid, data: fields }).then(() => {
					this.$notice(`User updated!`)
				}).catch(error => {
					this.error = error.message || error
				})
				.finally(() => {
					this.isLoading = false
				})
			} else {
				this.isLoading = true
				this.$store.dispatch('user/create', fields).then(() => {
					this.$notice(`User created!`)
				}).catch(error => {
					this.error = error.message || error
				})
				.finally(() => {
					this.isLoading = false
				})
			}
		},
		remove(fields) {
			if (!fields.uid) return

			this.$confirm(`Are you sure you want to remove "${fields.firstName} ${fields.lastName}"?`, () => {
				this.error = null
				this.isLoading = true
				this.$store.dispatch('user/remove', fields.uid).then(() => {
					this.$notice(`User removed!`)
					this.$router.back()
				}).catch(error => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})

			})
		},
		getSalesforceId() {
			if (this.$route.params.uid) {
				this.isLoading = true
				this.$store.dispatch('user/salesforce', this.$route.params.uid)
					.then(() => {
						this.$notice(`Salesforce Connected`)
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})
			}
		},
	},
	watch: {
		user: {
			immediate: true,
			handler: function( userProp) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...pick(userProp, ['uid', 'address', 'email', 'firstName', 'lastName', 'organization', 'permissions', 'phone', 'status',]),
					...{ orgId: get(userProp, 'organization.orgId') },
					...{ settings: {...this.defaults.settings, ...userProp.settings }}
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">
.form-user {
}
</style>
